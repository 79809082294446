<template>
    <u-sheet-grey><slot /></u-sheet-grey>
</template>

<script>
import USheetGrey from "@/components/publish/styles/sheet/u-sheet-grey.vue";

export default {
    props: {
    },
    components: {
        USheetGrey,
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
