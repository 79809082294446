<template>
    <v-sheet color="grey lighten-5"><slot /></v-sheet>
</template>

<script>
export default {
    props: {
    },
    components: {
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.v-sheet {
    padding: 12px;
}
@media (min-width: 768px) {
    .v-sheet {
        padding: 24px;
    }
}
</style>